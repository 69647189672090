<template>
    <div style="padding: 20px 100px;box-sizing: border-box;">
        <h3>组织者信息</h3>
        <div style="text-align: right;">
            <el-button type="primary" size="small" @click="saveInfo">保存</el-button>
        </div>
        <el-form :model="organizerInfo" :rules="rules" ref="organizerForm">
            <el-form-item label="主办方名称" prop="title">
                <el-input v-model="organizerInfo.title" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="用户名" prop="name">
                <el-input v-model="organizerInfo.name" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="邮箱" prop="email">
                <el-input v-model="organizerInfo.email" type="email" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
                <el-input v-model="organizerInfo.password" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="发票抬头" prop="invoice_title">
                <el-input v-model="organizerInfo.invoice_title" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="开票税号" prop="invoice_tax_num">
                <el-input v-model="organizerInfo.invoice_tax_num" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="透明底Logo">
                <el-upload class="avatar-uploader" action="/" :show-file-list="false"
                    :before-upload="(file) => beforeTransLogoUpload(file)">
                    <img v-if="organizerInfo.logo_transbg_url" :src="organizerInfo.logo_transbg_url"
                        style="object-fit: cover;" class="el-icon-plus avatar-uploader-icon">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </el-form-item>
            <el-form-item label="不透明底Logo">
                <el-upload class="avatar-uploader" action="/" :show-file-list="false"
                    :before-upload="(file) => beforeLogoUpload(file)">
                    <img v-if="organizerInfo.logo_url" :src="organizerInfo.logo_url" style="object-fit: cover;"
                        class="el-icon-plus avatar-uploader-icon">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </el-form-item>

            <el-form-item label="">
                <label>介绍</label>
                <quill-editor class="editor" style="margin-bottom:20px" ref="myTextEditor" v-model="organizerInfo.intro"
                    :options="editorOption">
                </quill-editor>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import {
    getUserId
} from '../../utils/store'
import {
    getQiniuToken,
    updateOrganizer,
    getOrganizerInfo
} from '../../api/api'
import * as qiniu from "qiniu-js"
export default ({
    name: 'index',
    data() {
        return {
            organizerInfo: {

            },
            editorOption: {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
                        ['blockquote', 'code-block'], // 引用  代码块
                        [{ header: 1 }, { header: 2 }], // 1、2 级标题
                        [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
                        [{ script: 'sub' }, { script: 'super' }], // 上标/下标
                        [{ indent: '-1' }, { indent: '+1' }], // 缩进
                        // [{'direction': 'rtl'}],                         // 文本方向
                        [{ size: ['small', false, 'large', 'huge'] }], // 字体大小
                        [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
                        [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
                        [{ font: [] }], // 字体种类
                        [{ align: [] }], // 对齐方式
                        ['clean'], // 清除文本格式
                        ['link', 'image', 'video'] // 链接、图片、视频
                    ] // 工具菜单栏配置
                },
                placeholder: '', // 提示
                readyOnly: false, // 是否只读
                theme: 'snow', // 主题 snow/bubble
                syntax: true // 语法检测
            },
            rules: {
                title: [
                    { required: true, message: 'Please input', trigger: 'blur' }
                ],
                name: [
                    { required: true, message: 'Please input', trigger: 'blur' }
                ],
                email: [
                    { required: true, message: 'Please input', trigger: 'blur' }
                    // { validator: checkEmail, trigger: 'blur' }
                ],
                password: [
                    { required: true, message: 'Please input', trigger: 'blur' }
                ],
            },
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        fetchData() {
            getOrganizerInfo(getUserId()).then((res) => {
                this.organizerInfo = res.data.data
            })
        },
        saveInfo() {
            this.$refs.organizerForm.validate((val) => {
                if (val) {
                    let params = {
                        id: getUserId(),
                        name: this.organizerInfo.name,
                        email: this.organizerInfo.email,
                        password: this.organizerInfo.password,
                        intro: this.organizerInfo.intro,
                        logo_url: this.organizerInfo.logo_url,
                        logo_transbg_url: this.organizerInfo.logo_transbg_url,
                        title: this.organizerInfo.title,
                        invoice_title: this.organizerInfo.invoice_title,
                        invoice_tax_num: this.organizerInfo.invoice_tax_num,
                    }
                    updateOrganizer(params).then((res) => {
                        this.$message.success('更新成功')
                        this.fetchData()
                    })
                }
            })
        },
        async beforeTransLogoUpload(file) {
            const isPNG = file.type === 'image/png';//透明底只能是png格式的图片
            if (!isPNG) {
                this.$message.error('上传透明底LOGO图片只能是 PNG 格式!');
                return
            }
            const result = await getQiniuToken()
            if (result.data.code === 0) {
                const token = result.data.data
                this.observable = qiniu.upload(
                    file,
                    new Date().getTime() + file.name,
                    token
                )
                const observer = {
                    complete: (res) => {
                        this.organizerInfo.logo_transbg_url = "http://mainbucket.reachable-edu.com/" + res.key
                        console.log('url:' + this.organizerInfo.logo_transbg_url)
                    },
                }
                this.observable.subscribe(observer)
            }
        },

        async beforeLogoUpload(file) {
            const result = await getQiniuToken()
            if (result.data.code === 0) {
                const token = result.data.data
                this.observable = qiniu.upload(
                    file,
                    new Date().getTime() + file.name,
                    token
                )
                const observer = {
                    complete: (res) => {
                        this.organizerInfo.logo_url = "http://mainbucket.reachable-edu.com/" + res.key
                    },
                }
                this.observable.subscribe(observer)
            }
        },
    }
})
</script>
<style>
.ql-container {
    height: 160px !important;
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>
